* {
  box-sizing: border-box;
}

/* CSS variabelen voor Light en Dark mode */
:root {
  --background-color: white;
  --text-color: #111528;
  --searchbox-background: rgba(255, 255, 255, 0.95); /* Light mode search box background */
  --searchbox-border: rgba(0, 0, 0, 0.1); /* Light mode search box border */
  --searchbox-text-color: #111528; /* Light mode search text color */
  --searchbox-placeholder-color: #666; /* Light mode placeholder color */
  --cookiewall-bg-color: #f1f1f1; /* Light mode background */
  --cookiewall-text-color: #000000; /* Light mode text */
  --cookiewall-link-color: #0000EE; /* Light mode link */
  --cookiewall-button-bg: #007BFF; /* Light mode button background */
  --cookiewall-button-text: #FFFFFF; /* Light mode button text */
}

.darkmode {
  --cookiewall-bg-color: #333333; /* Dark mode background */
  --cookiewall-text-color: #FFFFFF; /* Dark mode text */
  --cookiewall-link-color: #1E90FF; /* Dark mode link */
  --cookiewall-button-bg: #444444; /* Dark mode button background */
  --cookiewall-button-text: #FFFFFF; /* Dark mode button text */
  --background-color: #070b1d;
  --text-color: #ffffff;
  --searchbox-background: #101425;
  --searchbox-border: rgba(255, 255, 255, 0.2); /* Dark mode search box border */
  --searchbox-text-color: #ffffff; /* Dark mode search text color */
  --searchbox-placeholder-color: #aaa; /* Dark mode placeholder color */
}

/* Algemene body instellingen */
body {
  margin: 0;
  padding: 0;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  height: calc(100vh - 60px);
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.5s ease, color 0.5s ease;
}

body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.app {
  margin: auto;
}

/* Zoekbalk styling */
.customSearchBox {
  width: 100% !important;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 2;
  background-color: inherit;
}

.customSearchField {
  width: 78% !important;
  height: 26px !important;
  border-radius: 13px !important;
  border: 1px solid var(--searchbox-border) !important;
  background-color: var(--searchbox-background) !important;
  color: var(--searchbox-text-color) !important;
  transition: all 0.3s ease;
}


.customSearchField::placeholder {
  color: var(--searchbox-placeholder-color) !important;
}

.customSearchField:focus {
  box-shadow: 0px 1px 5px 0px var(--searchbox-border) !important;
}

.customSearchField:hover {
  background-color: var(--searchbox-background) !important;
}

.customSearchField .MuiOutlinedInput-root {
  border: none !important;
  box-shadow: none !important;
}

.customSearchField .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.customSearchField.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
  box-shadow: none !important;
}

.customSearchField .MuiOutlinedInput-input {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  vertical-align: middle !important;
  line-height: 16px !important;
}

/* Andere bestaande stijlen */
@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

html {
  scrollbar-width: none;
}

.spark-svg {
  animation: pulse 1s infinite;
}

.fade-out {
  animation-name: fadeOut;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 0.5s; /* Duur van de fade-out */
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Container for the loading animation */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000; /* Background color, can be adjusted */
  z-index: 1000; /* Ensure it's above all other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* The fuse of the firework */
.fuse {
  width: 10px;
  height: 50px;
  background-color: #ccc; /* Color of the fuse, can be adjusted */
  animation: burn 3s linear infinite; /* 3-second animation that runs indefinitely */
}

/* Animation for the fuse */
@keyframes burn {
  0% {
    height: 50px;
  }
  100% {
    height: 0;
  }
}

@media (max-width: 600px) {
  .search-open-margin .shift-on-search {
    margin-top: 90px !important;
  }
}


@media (max-width: 600px) {
  .search-open-margin .shift-on-search-mobile {
    margin-top: 90px !important;
  }
  .hide-on-mobile {
    display: none;
  }
}

/* Verberg de tekst "VUURWERK BRUCHEM" alleen op schermen kleiner dan 600px */
@media (max-width: 600px) {
  .vuurwerk-bruchem-text {
    display: none !important;
  }
  .vuurwerk-bruchem-icon {
    display: inline !important;
  }
}

/* Zorg ervoor dat de tekst "VUURWERK BRUCHEM" zichtbaar is op schermen groter dan 600px */
@media (min-width: 601px) {
  .vuurwerk-bruchem-text {
    display: inline !important;
  }
  .vuurwerk-bruchem-icon {
    display: none !important;
  }
}

.carousel-slider {
  height: 100%;
}

.carousel-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.items-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Styles for MainCarousel */
.main-carousel {
  width: 70%;
  float: left;
}


/* New Styles for MainCarousel */
.main-carousel {
  width: 70%;
  float: left;
}


/* Styles for MainCarousel */
.carousel-container {
  display: flex;
}

.main-carousel {
  width: 70%;
}


.fade-effect {
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.fade-effect.visible {
  opacity: 1;
}

/* Styles for VerkoopvoorwaardenModal */

.dialog-content {
  font-weight: 600;
  margin: 10px 0;
}

.black-ops-one-regular {
  font-family: "Black Ops One", system-ui;
  font-weight: 400;
  font-style: normal;
}

.dialog-content {
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
}

h1 {
  color: #e3197e; /* Roze kleur */
}

h2 {
  color: #e3197e; /* Roze kleur */
  border-bottom: 2px solid #e3197e; /* Roze onderstreping */
  padding-bottom: 5px;
}

.h2noborder {
  color: #e3197e; /* Roze kleur */
  padding-bottom: 5px;
  border-bottom: none; /* Verwijder de onderstreping */
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

p {
  margin-bottom: 10px;
}

.loading-indicator p {
  font-size: 20px;
  font-weight: bold;
}

.best-selling-carousel {
  margin-top: 40px;
}

.block-title.title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.owl-theme .owl-nav [class*='owl-'] {
  background: #869791;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #555;
}

.owl-carousel .item {
  text-align: center;
  padding: 15px;
}

.thumbs-wrapper.axis-vertical {
  display: none;
}

.css-10obtsr {
  height: 100% !important;
}

.socials-container {
  display: flex;
  gap: 13px; /* Reduce the gap to 65% of the original */
}

.socials-container a {
  background-color: white;
  padding: 0.65em; /* Reduce padding to 65% */
  border-radius: 50%;
  height: 41.6px; /* Reduce height to 65% */
  width: 41.6px; /* Reduce width to 65% */
  box-sizing: border-box;
  flex-shrink: 0;
  display: grid;
  place-items: center;
  box-shadow: 0 3.25px 6.5px rgba(0, 0, 0, 0.05); /* Adjust shadow for the smaller size */
  position: relative;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.socials-container a svg {
  height: 20.8px; /* Reduce icon size to 65% */
  width: 20.8px; /* Ensure width is also reduced */
  fill: currentColor;
}

.socials-container a::before {
  content: attr(data-social);
  position: absolute;
  background-color: var(--accent-color);
  color: white;
  text-decoration: none;
  font-size: 0.9em; /* Adjust tooltip text size to around 65% */
  padding: 0.325em 0.65em; /* Adjust padding for the tooltip */
  border-radius: 100px;
  box-shadow: 0 3.25px 6.5px rgba(0, 0, 0, 0.05);
  transform: translateY(-19.5px) rotate(25deg); /* Adjust transform for smaller size */
  opacity: 0;
  transition: transform 200ms cubic-bezier(.42, 0, .44, 1.68), opacity 200ms cubic-bezier(.42, 0, .44, 1.68);
}

.socials-container a:hover {
  background-color: var(--accent-color);
  fill: white;
}

.socials-container a::after {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  border-left: 5.2px solid transparent; /* Adjust arrow size */
  border-right: 5.2px solid transparent;
  border-top: 6.5px solid var(--accent-color);
  transform: translateY(0) rotate(25deg);
  opacity: 0;
  transition: transform 200ms cubic-bezier(.42, 0, .44, 1.68), opacity 200ms cubic-bezier(.42, 0, .44, 1.68);
}

.socials-container a:hover::before {
  transform: translateY(-42.25px) rotate(0); /* Adjust hover transform */
  opacity: 1;
}

.socials-container a:hover::after {
  transform: translateY(-27.3px) rotate(0); /* Adjust hover transform */
  opacity: 1;
}

#theme-switch {
  height: 50px;
  width: 50px;
  padding: 0;
  border-radius: 50%;
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

#theme-switch img {
  width: 24px;
  height: 24px;
}

.darkmode #theme-switch img.light-icon {
  display: block;
}

.darkmode #theme-switch img.dark-icon {
  display: none;
}

#theme-switch img.light-icon {
  display: none;
}

#theme-switch img.dark-icon {
  display: block;
}


